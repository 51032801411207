'use client';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Col, Divider, Modal, Row, Typography, Form, Input, Select } from 'antd';
import { signIn, useSession } from 'next-auth/react';
import { ExclamationCircleFilled, GoogleOutlined } from '@ant-design/icons';
import { useSearchParams, useRouter } from 'next/navigation';

import i18n from '@shared/locales';

const { Title, Text } = Typography;
const { Option } = Select;

const Login = () => {
  const { t } = useTranslation(['login', 'common']);
  const [form] = Form.useForm();
  const { status } = useSession();
  const [openModal, setOpenModal] = useState(false);

  const searchParams = useSearchParams();
  const error = searchParams.get('error');
  const router = useRouter();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      signIn('authKey', { authKey: values.authKey, callbackUrl: '/' });
    });
  };

  const handleChangeLang = (value: string) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    if (error?.length) {
      setOpenModal(true);
      return;
    }

    setOpenModal(false);
  }, [error]);

  const getModalContents = () => {
    let contents = '';

    switch (error) {
      case 'ACCOUNT_IS_NOT_ALLOWED':
        contents = `${t('login:notAllowedErrorMessage1')}<br/>${t(
          'login:notAllowedErrorMessage2',
        )}`;
        break;
      case 'Invalid auth key':
        contents = `${t('login:keyLoginErrorMessage1')}<br/>${t('login:keyLoginErrorMessage2')}`;
        break;
      default:
        contents = `${t('login:loginErrorMessage1')}<br/>${t('login:loginErrorMessage2')}`;
        break;
    }

    return (
      <div
        style={{ padding: '0 38px' }}
        dangerouslySetInnerHTML={{
          __html: contents,
        }}
      />
    );
  };

  return (
    <>
      <Row align={'middle'} justify={'center'} style={{ height: '80vh' }}>
        <Col flex={'400px'} style={{ textAlign: 'center' }}>
          <Title style={{ marginBottom: 32, fontSize: 24 }}>{t('common:title')}</Title>

          <Button
            loading={status === 'loading'}
            type='primary'
            block
            size='large'
            icon={<GoogleOutlined />}
            onClick={() => signIn('google')}
          >
            {t('login:login')}
          </Button>
          <Divider />
          <Form form={form}>
            <Form.Item name='authKey'>
              <Input placeholder={t('login:inputKey')} />
            </Form.Item>
            <Form.Item>
              <Button type='primary' block size='large' onClick={() => handleSubmit()}>
                {t('login:keyLogin')}
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Text style={{ fontSize: 12 }}>
            Copyright © 2023{' '}
            <a href={'https://humanscape.io/'} style={{ color: '#000' }}>
              Humanscape
            </a>
            . All rights reserved.
          </Text>
          <Select value={i18n.language} onChange={handleChangeLang} style={{ margin: 20 }}>
            <Option value='ko-KR'>{t('common:lang', { lng: 'ko' })}</Option>
            <Option value='en-US'>{t('common:lang', { lng: 'en' })}</Option>
          </Select>
        </Col>
      </Row>
      <Modal
        open={openModal}
        width={400}
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
            <ExclamationCircleFilled style={{ fontSize: '22px', color: 'orange' }} />
            <span>{t('login:loginError')}</span>
          </div>
        }
        footer={
          <Button
            type='primary'
            onClick={() => {
              router.replace('/');
            }}
          >
            {t('common:confirm')}
          </Button>
        }
        closable={false}
      >
        {getModalContents()}
      </Modal>
    </>
  );
};
export default Login;
